<template>
  <div>Bitte wählen Sie eine Vorlage zum befüllen der Zeile:</div>
  <br>
  <div class="form">
    <multiselect :id="id" :options="options" :placeholder="title" v-model="value" :custom-label="customLabel"
                 tagPlaceholder="Eingabe mit Enter" selectLabel="Auswahl mit Enter" selectedLabel="Ausgewählt"
                 deselectLabel="Entfernen mit Enter" @select="select">
      <template v-slot:noOptions>Keine Einträge vorhanden</template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "@suadelabs/vue3-multiselect";
import {Store} from "@/js/Store";

export default {
  name: "PopUpInput.vue",
  components: {Multiselect},
  props: ["id", "title", "options", "modelValue", "customLabel"],
  data() {
    return {
      value: []
    }
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value)
      Store.store({key: "POPUPFORM", value: this.value})
    },
    modelValue: function (newVal) {
      let _this = this
      if (newVal !== null) {
        _this.options.forEach(option => {
          if (option.id === newVal.id) {
            console.log(option)
            _this.value = option
          }
        })
      }
    }
  },
  methods: {
    select() {
      this.$emit("update:modelValue", this.value)
    }
  }
}
</script>

<style scoped>

</style>