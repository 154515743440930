import Swal from "sweetalert2";

export class Alert {

    static AuthLevels = {
        Root: 1,
        Admin: 2,
        Manager: 3,
        User: 4,
    }

    static toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    static makeToast(icon, title) {
        this.toast.fire({
            icon: icon,
            title: title
        })
    }

    static async makeURLPopUp(title, placeholder="z.B. https://optigov.de"){
        const { value: url } = await Swal.fire({
            input: 'url',
            inputLabel: title,
            inputPlaceholder: placeholder,
            validationMessage: "Bitte gültige URL eingeben"
        })
        return url
    }

    static makePopUp(title, confirmFunction, denyFunction = () => {}, confirmButtonText = "Ja", confirmButtonColor = "#506de2" , denyButtonText = "Abbrechen", showDenyButton = true) {
        this.makePopUpWithText(title, undefined, confirmFunction, denyFunction, confirmButtonText, confirmButtonColor, denyButtonText, showDenyButton);
    }

    static makePopUpWithText(title, text, confirmFunction, denyFunction = () => {}, confirmButtonText = "Ja", confirmButtonColor = "#506de2" , denyButtonText = "Abbrechen", showDenyButton = true) {
        Swal.fire({
            title: title,
            text: text,
            confirmButtonText: confirmButtonText,
            confirmButtonColor: confirmButtonColor,
            showDenyButton: showDenyButton,
            denyButtonText: denyButtonText,
            denyButtonColor: "#dfdfdf",
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                confirmFunction()
            } else {
                denyFunction()
            }
        })
    }
    static makePopUpWithTextAndInput(title, text, confirmFunction, denyFunction = () => {}, confirmButtonText = "Ja", confirmButtonColor = "#506de2" , denyButtonText = "Abbrechen", showDenyButton = true) {
        Swal.fire({
            title: title,
            text: text,
            input: 'textarea',
            confirmButtonText: confirmButtonText,
            confirmButtonColor: confirmButtonColor,
            showDenyButton: showDenyButton,
            denyButtonText: denyButtonText,
            denyButtonColor: "#dfdfdf",
        }).then((result) => {
            if (result.isConfirmed) {
                confirmFunction(result.value)
            } else {
                denyFunction()
            }
        })
    }
    static makePopUpWithCustomHTML(title, text, html, confirmFunction, denyFunction = () => {}, didRender = () => {}, confirmButtonText = "Ja", confirmButtonColor = "#506de2" , denyButtonText = "Abbrechen", showDenyButton = true) {
        Swal.fire({
            title: title,
            text: text,
            html: html,
            confirmButtonText: confirmButtonText,
            confirmButtonColor: confirmButtonColor,
            willOpen: didRender,
            showDenyButton: showDenyButton,
            denyButtonText: denyButtonText,
            denyButtonColor: "#dfdfdf",
            customClass: {
                htmlContainer: 'overflowVisible foreground',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                confirmFunction()
            } else {
                denyFunction()
            }
        })
    }
}