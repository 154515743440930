import {Api} from "@/js/Api";
import {Alert} from "@/js/Alert";
import router from "@/router";
import {Praxis} from "@/js/Praxen";

export class Vorlage {

    static async createVorlage(name, vorlage) {
        let _vorlage = {
            name: name,
            entries: []
        }
        let id = (await Api.apiCall({
            query: `
                mutation CreateTemplate($vorlage: TemplateInput!){
                        createTemplate(template: $vorlage){
                            id
                        }
                }`,
            variables: {vorlage: _vorlage},
        })).data.data.createTemplate.id
        await this.createVorlagenEintraege(id, vorlage)
        Alert.makeToast('success', "Vorlage wurde erstellt.")
        router.replace('/vorlagen')
    }

    static async getVorlagen() {
        return (await Api.apiCall({
            query: `{
                allTemplates{
                   id
                   name
                   entries {
                     id
                        date
                        value
                        office {
                            id
                            name
                        }
                        time
                   }
                }
            }`,
            needToken: false
        })).data.data.allTemplates
    }

    static async getVorlage(id) {
        return (await Api.apiCall({
            query: `{
                template(id: ${id}) {
                    id
                    name
                    entries {
                        id
                        date
                        value
                        office {
                            id
                            name
                        }
                        time
                    }
                }
            }`
        })).data.data.template
    }

    static async saveVorlage(id, name, vorlage) {
        await this.cleanVorlagenEintraege(id)
        await this.createVorlagenEintraege(id, vorlage)
        let _vorlage = {
            name: name
        }
        await Api.apiCall({
            query: `
                    mutation UpdateVorlage($vorlage: TemplateInput!){
                            updateTemplate(id: ${id}, template: $vorlage)
                    }`,
            variables: {vorlage: _vorlage},
            success: () => {
                Alert.makeToast('success', "Vorlage wurde bearbeitet.")
            }
        })
    }

    static async deleteVorlage(id) {
        return (await Api.apiCall({
            query: `mutation {
                deleteTemplate(id:${id})
            }`,
        })).data.data.deleteTemplate
    }

    static async createVorlagenEintraege(id, vorlage) {
        let query = `mutation CreateTemplateentry(`
        let queryMid = ``
        let variables = {}
        let counter = 0
        let praxen = await Praxis.getPraxen()
        vorlage.forEach(tag => {
            Object.keys(tag).splice(1).forEach(praxis => {
                Object.keys(tag[praxis]).splice(1).forEach(time => {
                    if (tag[praxis][time] !== null) {
                        variables["templateentry" + counter] = {
                            date: tag.tag.toUpperCase(),
                            value: tag[praxis][time],
                            template: parseInt(id),
                            office: parseInt(praxen.find(x => x.name === praxis).id),
                            time: time.toUpperCase()
                        }
                        query += `$templateentry` + counter + `: TemplateEntryInput!,`
                        queryMid += `m` + counter + `: createTemplateentry(templateentry: $templateentry` + counter + `){
                            id
                        }`
                        counter++
                    }
                })
            })
        })
        console.log(id, vorlage, counter)
        if (counter === 0) return []
        query = query.substring(0, query.length - 1)
        query += `){` + queryMid + `}`
        let response = (await Api.apiCall({
            query: query,
            variables: variables,
        })).data.data
        let ids = []
        Object.keys(response).forEach(entry => {
            ids.push(response[entry].id)
        })
        return ids
    }

    static async cleanVorlagenEintraege(id) {
        let entries = (await Vorlage.getVorlage(id)).entries
        let query = `mutation {`
        let counter = 0
        entries.forEach(entry => {
            query += `m` + counter + `:deleteTemplateentry(id: ${entry.id})`
            counter++
        })
        query += `}`
        if (counter !== 0) {
            return (await Api.apiCall({
                query: query,
            })).data.data.deleteTemplateentry
        }
    }
}