<template>
  <div class="inputHolder">
    <label :for="id">{{ title }}</label>
    <multiselect :id="id" :options="options" :placeholder="title" :allow-empty="allowEmpty === undefined ?  true : allowEmpty" v-model="value" :custom-label="customLabel" tagPlaceholder="Eingabe mit Enter" selectLabel="Auswahl mit Enter" selectedLabel="Ausgewählt" deselectLabel="Entfernen mit Enter" @select="select === undefined ? () => {} : select">
      <template v-slot:noOptions>Keine Einträge vorhanden</template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "@suadelabs/vue3-multiselect";

export default {
  name: "SelectInput.vue",
  components: {Multiselect},
  props: ["id", "title", "options", "modelValue", "customLabel", "allowEmpty", "select"],
  data () {
    return {
      value : []
    }
  },
  watch: {
    value(){
      this.$emit("update:modelValue", this.value)
    },
    modelValue: function (newVal) {
      let _this = this
      if(newVal !== null) {
        _this.options.forEach(option => {
          if(option.id === newVal.id) {
            _this.value = option
          }
        })
      }
    }
  },
  mounted () {
  }
}
</script>