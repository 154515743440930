<template>
  <div>
    <!-- Container for Login View -->
    <div class="container">
        <div class="text mTT">Bitte melden Sie sich mit <br>
          Ihren Zugangsdaten bei DocVacation an.</div>
        <!-- Form for Login -->
        <input id="email" class="input mB mT" type="email" placeholder="E-Mail" autocapitalize="none" autocomplete="off" autocorrect="off"/>
        <input id="password" class="input" type="password" placeholder="Passwort" @keyup="keyup"/>
        <!-- Login Button -->
        <div>
          <button type="button" class="mTT" @click="login()">Anmelden</button>
        </div>
        <!-- Forgot Password Button -->
        <div>
          <button type="button" class="secondary" @click="navigateTo('/passwort-vergessen')">Passwort vergessen</button>
        </div>
      </div>
    </div>
</template>

<script>


import router from "@/router";
import {User} from "@/js/User";
import {Alert} from "@/js/Alert";

export default {
  name: "Login",
  beforeCreate() {
    document.title = "Login"
    if(User.isLoggedIn()){
      router.replace("/plan")
    }
  },
  methods: {
    keyup(event) {
      if(event.keyCode === 13){
        this.login()
      }
    },
    login() {
      let email = document.getElementById("email").value
      let password = document.getElementById("password").value

      // check for email
      if(email.trim()===""){
        Alert.makeToast('error', "E-Mail ist leer!")
        return
      }

      // check for password
      if(password.trim()===""){
        Alert.makeToast('error', "Passwort ist leer!")
        return
      }
      User.logIn({email: email, password: password})
    },
    navigateTo(routePath, param = "") {
      router.push({ path: routePath + param });
    },
  }
}
</script>

<style scoped>

</style>
