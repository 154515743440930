import {createRouter, createWebHistory} from "vue-router"
import Login from "@/views/Login";
import LoggedOut from "@/views/LoggedOut";
import {User} from "@/js/User";
import Aerzte from "@/views/Aerzte";
import Praxen from "@/views/Praxen";
import Vorlagen from "@/views/Vorlagen";
import Plan from "@/views/Plan";
import AerzteDetail from "@/views/AerzteDetail";
import PraxenDetail from "@/views/PraxenDetail";
import VorlagenDetail from "@/views/VorlagenDetail";
import Oeffentlich from "@/views/Oeffentlich.vue";
import MeinPlan from "@/views/MeinPlan";

const router = createRouter({
    mode: 'history',
    history: createWebHistory('/'),
    routes: [
        //Redirect to Login
        {
            path: '/',
            redirect: '/login'
        },
        //Login View
        {
            path: '/login',
            component: Login
        },
        //Logout View
        {
            path: '/logout',
            component: LoggedOut
        },
        {
            path: '/praxen',
            component: Praxen,
            meta: {
                requiresLoggedIn: true,
            }
        },
        {
            path: '/praxen/neu',
            component: PraxenDetail,
            meta: {
                requiresLoggedIn: true,
            }
        },
        {
            path: '/praxen/:id',
            component: PraxenDetail,
            meta: {
                requiresLoggedIn: true,
            }
        },
        {
            path: '/aerzte',
            component: Aerzte,
            meta: {
                requiresLoggedIn: true,
            }
        },
        {
            path: '/aerzte/neu',
            component: AerzteDetail,
            meta: {
                requiresLoggedIn: true,
            }
        },
        {
            path: '/aerzte/:id',
            component: AerzteDetail,
            meta: {
                requiresLoggedIn: true,
            }
        },
        {
            path: '/vorlagen',
            component: Vorlagen,
            meta: {
                requiresLoggedIn: true,
            }
        },
        {
            path: '/vorlagen/neu',
            component: VorlagenDetail,
            meta: {
                requiresLoggedIn: true,
            }
        },
        {
            path: '/vorlagen/:id',
            component: VorlagenDetail,
            meta: {
                requiresLoggedIn: true,
            }
        },
        {
            path: '/plan',
            component: Plan,
            meta: {
                requiresLoggedIn: true,
            }
        },
        {
            path: '/oeffentlich/aJ0bCxpQa28F1o5',
            component: Oeffentlich,
        },
        {
            path: '/meinPlan/hf84537h54238f3rweff/:id',
            component: MeinPlan,
        },
        ],
})
router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresLoggedIn)) {
        if (User.isLoggedIn()) {
            next()
            return
        }

        window.tmpLoginRedirect = window.location.pathname.slice(5)
        next('/login')
    } else {
        next()
    }
})
export default router
