import {Store} from "@/js/Store";
import router from "@/router";
import {Api} from "@/js/Api";

export class User {

    /**
     * Makes an attempt to log in a user. If successful it will resolve the returned promise,
     * otherwise it will reject.
     * @param email
     * @param password
     * @returns {Promise<unknown>}
     */
    static async logIn({email, password}) {
        let _this = this
        await Api.apiCall({
            needToken: false,
            query: `mutation {
                                login( email: "${email}", password: "${password}"){
                                    access_token
                                    refresh_token
                                 } 
                            }`,
            success: async (response) => {
                _this._storeUserInfo({
                    token: response.data.data.login.access_token,
                    refresh_token: response.data.data.login.refresh_token,
                })
                let path = window.tmpLoginRedirect
                await router.replace(path);
            },
            badUserInputText: "E-Mail oder Passwort sind falsch. Versuchen Sie erneut.",
            tooManyAttemptsText: "Zu viele Anmeldeversuche. Versuchen Sie es in einer Minute erneut.",
        })
    }

    /**
     * Logs out a user
     */
    static logOut() {
        if (User.isLoggedIn()) {
            Store.destroy({key: "USER_INFORMATION"})
            Store.destroy({key: "POPUPFORM"})
            router.push('/logout')
        } else {
            console.warn("user : you're trying to log out a user that is not logged in. See trace: ")
            console.trace()
        }
    }

    /**
     * gets new Tokens
     */

    static async newTokens() {
        let refresh_token = User.getRefreshToken()
        return (await Api.apiCall({
            needToken: false,
            query: `
            mutation{
                aktualisiereToken(refresh_token: "${refresh_token}"){
                    access_token
                    refresh_token
            }
}`
        })).data.data.aktualisiereToken
    }

    /**
     * Stores user information in the local storage
     * @param token
     * @param email
     * @param name
     */
    static _storeUserInfo({token, refresh_token, name}) {
        let userInformation = {
            token: token,
            refresh_token: refresh_token,
            name: name,
        }
        Store.store({key: "USER_INFORMATION", value: userInformation})
    }

    /**
     * Returns whether you are logged in or not
     * @returns {boolean}
     */
    static isLoggedIn() {
        return this.getRefreshToken() !== undefined;
    }

    /**
     * Returns the user information as an JSON object
     * @returns {any|string}
     */
    static getInformation() {
        return Store.load({key: "USER_INFORMATION"});
    }

    /**
     * Returns the token of the user. If he/she has a MFA token, this one is getting used
     * @returns {*|undefined}
     */
    static async getToken() {
        let infos = User.getInformation()
        let token = infos.token
        if(User.getExpTime(token) <= Math.floor(Date.now() / 1e3)) {
            let tokens = await User.newTokens()
            infos.token = tokens.access_token
            User._storeUserInfo(infos)
            token = tokens.access_token
        }
        return token;
    }

    /**
     * Returns the refresh token of the user. If he/she has a refresh token, this one is getting used
     * @returns {*|undefined}
     */
    static getRefreshToken() {
        return this.getInformation() !== undefined ? this.getInformation().refresh_token : undefined;
    }

    /**
     * Returns the exp time of access token of the user.
     * @returns {*|undefined}
     */
    static getExpTime(token) {
        return (this._decodeJWT({token: token})).exp
    }

    /**
     * Decodes a JWT into JSON object
     * @param token
     * @returns {any}
     * @private
     */
    static _decodeJWT({token}) {
        let base64Url = token.split('.')[1]
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        }).join(''))
        return JSON.parse(jsonPayload)
    }
}
