<template>
  <div class="topBar">
    <div id="headerTopBar" class="title">
      {{ title }}
    </div>
    <vue-feather type="log-out" class="logout" stroke-width="3" @click="logout"></vue-feather>
  </div>
</template>

<script>
import {User} from "@/js/User";
export default {
  name: "TopBar.vue",
  data() {
    return {
      title: window.topbar.title,
    }
  },
  methods: {
    logout() {
      User.logOut()
    }
  }
}
</script>
