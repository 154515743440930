<template>
  <div>
    <!-- TopBar -->
    <TopBar/>
    <!-- SideNav -->
    <SideNav/>
    <!-- content -->
    <div class="content">
      <button class="icon" @click="$router.push('/vorlagen/neu')">
        <vue-feather type="plus"></vue-feather>
        Vorlage anlegen
      </button>
      <div class="tableHolder">
        <input id="search" type="text" class="input" placeholder="Suche Vorlage">
        <div class="table">
          <div id="vorlagen"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from "@/components/TopBar";
import SideNav from "@/components/SideNav";
import router from "@/router";
import {Alert} from "@/js/Alert";
import {Table} from "@/js/Table";
import {Vorlage} from "@/js/Vorlage";

export default {
  name: "Vorlagen.vue",
  components: {TopBar, SideNav},
  data() {return {
    vorlagen :[]
  }},
  beforeMount() {
    window.topbar.title = "Alle Vorlagen"
    document.title = "Alle Vorlagen"
  },
  async mounted() {
    let _this = this
    this.vorlagen = await Vorlage.getVorlagen()
    let addButton = function () {
      return '<span class="more"></span>'
    };
    let table = new Table(
        "#vorlagen",
        _this.vorlagen,
        [
          {title: "Name", field: "name",
            cellClick: (e, cell) => {
                router.push("/vorlagen/" + cell.getData().id)
            },},
          {
            formatter: addButton, align: "right", width: 10, headerSort: false, cellClick: function (e, cell) {
              _this.onContextMenu(e, cell)
            }
          },
        ],
        () => {
        },
    );
    table.initFilter()
  },
  methods: {
    onContextMenu(e, cell) {
      let data = cell.getData()
      //prevent the browser's default menu
      e.preventDefault();
      //shou our menu
      this.$contextmenu({
        x: e.x,
        y: e.y,
        items: [
          {
            label: "Bearbeiten",
            onClick: () => {
              router.push("/vorlagen/" + data.id)}
          },
          {
            label: "Löschen",
            onClick: () => {
              Alert.makePopUp(
                  '"' + data.name + '"' + " wirklich löschen?",
                  async () => {

                    if(await Vorlage.deleteVorlage(data.id)) {
                      Alert.makeToast('success', data.name + " wurde gelöscht.")
                      cell.getRow().delete()
                    } else {
                      Alert.makeToast('error', "Etwas ist schief gelaufen versuchen Sie es erneut.")
                    }

                  },
                  () => {},
                  `Ja, löschen`,
                  "#d90000"
              )
            }
          },
        ]
      });
    }
  }
}
</script>

<style scoped>

</style>