<template>
  <div>
    <!-- TopBar -->
    <TopBar/>
    <!-- SideNav -->
    <SideNav/>
    <!-- content -->
    <div class="content">
      <button class="icon mR" @click="$router.back()">
        <vue-feather type="arrow-left"></vue-feather>
        Zurück
      </button>
      <div class="form">
        <h2>Informationen</h2>
        <div>
          <TextInput :title="'Name*'" v-model="praxis.name"></TextInput>
          <SelectInput :title="'Zeiten*'" v-model="praxis.times" :options="zeitenOptions" :custom-label="showEnum" :allow-empty="false" v-if="newPraxis"></SelectInput>
          <div class="inputHolder" v-if="!newPraxis">
          <label for="zeiten">Zeiten</label>
            <input class="input" type="text" id="zeiten" readonly v-model="zeiten"/>
          </div>
        </div>
        <button class="fLeft" @click="savePraxis">Speichern</button>
        <button id="delete" class="icon fRight warning" @click="deletePraxis" v-if="!newPraxis">
          <vue-feather type="trash-2"></vue-feather>
          Löschen
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from "@/components/TopBar";
import SideNav from "@/components/SideNav";
import TextInput from "@/components/TextInput";
import {Alert} from "@/js/Alert";
import {Praxis} from "@/js/Praxen";
import router from "@/router";
import {Helper} from "@/js/Helper";
import SelectInput from "@/components/SelectInput";

export default {
  name: "PraxenDetail",
  components: {TopBar, SideNav, TextInput, SelectInput},
  data() {
    return {
      zeitenOptions: [{id: "GANZER_TAG"}, {id: "VORMITTAGS_NACHMITTAGS"}],
      newPraxis: true,
      praxis: {
        name: null,
        times: null,
      },
      zeiten: "",
    }
  },
  beforeMount() {
    if (window.location.pathname === "/praxen/neu") {
      this.newPraxis = true
      window.topbar.title = "Praxis anlegen"
      document.title = "Praxis anlegen"
    } else {
      this.newPraxis = false
      window.topbar.title = ""
      document.title = "Praxis"
    }
  },
  async mounted() {
    if (!this.newPraxis) {
      this.praxis = await Praxis.getPraxis(this.$route.params.id)
      this.praxis.times = {id: this.praxis.times}
      this.zeiten = this.showEnum(this.praxis.times)
      document.getElementById("headerTopBar").innerHTML = this.praxis.name
    } else {
      this.praxis.times = this.zeitenOptions[0]

    }
  },
  methods: {
    async savePraxis() {
      if (this.newPraxis) {
        if (this.praxis.name !== null && this.praxis.name !== "" && this.praxis.columns !== null && this.praxis.columns !== "") {
          let praxis = Helper.coerceObjectForGraphQL(this.praxis)
          await Praxis.createPraxis(praxis)
        } else {
          Alert.makeToast('info', "Bitte füllen Sie alle Felder aus.")
        }
      } else {
        if (this.praxis.name !== null && this.praxis.name !== "" && this.praxis.columns !== null && this.praxis.columns !== "") {
          let praxis = Helper.coerceObjectForGraphQL(this.praxis)
          await Praxis.savePraxis(this.$route.params.id, praxis)
        } else {
          Alert.makeToast('info', "Bitte füllen Sie alle Felder aus.")
        }
      }
    },
    async deletePraxis() {
      let _this = this
      Alert.makePopUp(
          '"' + _this.praxis.name + '"' + " wirklich löschen?",
          async () => {

            if(await Praxis.deletePraxis(_this.$route.params.id)) {
              Alert.makeToast('success', _this.praxis.name + " wurde gelöscht.")
              router.replace("/praxen")
            } else {
              Alert.makeToast('error', "Etwas ist schief gelaufen versuchen Sie es erneut.")
            }
          },
          () => {},
          `Ja, löschen`,
          "#d90000"
      )
    },
    showEnum(obj) {
      return Helper.formatEnum(obj.id)
    },
    showTextEnum(obj) {
      return Helper.formatEnum(obj.id)
    }
  }
}
</script>

<style scoped>

</style>