<template>
  <div>
    <!-- TopBar -->
    <TopBar/>
    <!-- SideNav -->
    <SideNav/>
    <!-- content -->
    <div class="content">
      <button class="icon mR" @click="$router.back()">
        <vue-feather type="arrow-left"></vue-feather>
        Zurück
      </button>
      <div class="form">
        <h2>Informationen</h2>
        <div>
          <TextInput :title="'Name*'" v-model="arzt.name"></TextInput>
          <div class="inputHolder">
            <label for="color">Farbe*</label>
          <input id="color" type="color" v-model="arzt.color"/>
          </div>
        </div>
        <div class="mBB" v-if="!newArzt">
          Link zum persönlichen Plan: <br>
          <a :href="link">{{link}}</a>
        </div>
        <button class="fLeft" @click="saveArzt">Speichern</button>
        <button id="delete" class="icon fRight warning" @click="deleteArzt" v-if="!newArzt">
          <vue-feather type="trash-2"></vue-feather>
          Löschen
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from "@/components/TopBar";
import SideNav from "@/components/SideNav";
import TextInput from "@/components/TextInput";
import {Arzt} from "@/js/Arzt";
import {Alert} from "@/js/Alert";
import {Helper} from "@/js/Helper";
import router from "@/router";

export default {
  name: "AerzteDetail",
  components: {TopBar, SideNav, TextInput},
  data() {
    return {
      newArzt: true,
      arzt: {
        name: null,
        color: null
      },
      link: "",
    }
  },
  beforeMount() {
    if (window.location.pathname === "/aerzte/neu") {
      this.newArzt = true
      window.topbar.title = "Arzt anlegen"
      document.title = "Arzt anlegen"
    } else {
      this.newArzt = false
      window.topbar.title = "Arzt"
      document.title = "Arzt"
    }
  },
  async mounted() {
    if (!this.newArzt) {
      this.arzt = await Arzt.getArzt(this.$route.params.id)
      document.getElementById("headerTopBar").innerHTML = this.arzt.name
    }
    this.link = "https://hno-zentrum-lippe-urlaubsplan.de/meinPlan/hf84537h54238f3rweff/" + this.arzt.id
  },
  methods: {
    async saveArzt() {
      if (this.newArzt) {
        if (this.arzt.name !== null && this.arzt.name !== "" && this.arzt.color !== null) {
          let arzt = Helper.coerceObjectForGraphQL(this.arzt)
          await Arzt.createArzt(arzt)
        } else {
          Alert.makeToast('info', "Bitte füllen Sie alle Felder aus.")
        }
      } else {
        if (this.arzt.name !== null && this.arzt.name !== "" && this.arzt.color !== null) {
          let arzt = Helper.coerceObjectForGraphQL(this.arzt)
          await Arzt.saveArzt(this.$route.params.id, arzt)
        } else {
          Alert.makeToast('info', "Bitte füllen Sie alle Felder aus.")
        }
      }
    },
    async deleteArzt() {
      let _this = this
      Alert.makePopUp(
          '"' + _this.arzt.name + '"' + " wirklich löschen?",
          async () => {

            if(await Arzt.deleteArzt(_this.$route.params.id)) {
              Alert.makeToast('success', _this.arzt.name + " wurde gelöscht.")
              router.replace("/aerzte")
            } else {
              Alert.makeToast('error', "Etwas ist schief gelaufen versuchen Sie es erneut.")
            }
          },
          () => {},
          `Ja, löschen`,
          "#d90000"
      )
    }
  }
}
</script>

<style scoped>

</style>