export class Helper {

    static clone(object) {
        return JSON.parse(JSON.stringify(object))
    }

    static coerceObjectForGraphQL(object) {
        let copy = this.clone(object)
        Object.keys(copy).forEach((key) => {
            if (typeof copy[key] === "object" && !Array.isArray(copy[key]) && copy[key] !== null) {
                if ("id" in copy[key]) {
                    copy[key] = copy[key].id
                }
            }
            if (typeof copy[key] === "object" && Array.isArray(copy[key]) && copy[key].length > 0
                && typeof copy[key][0] === "object" && !Array.isArray(copy[key][0]) && copy[key][0] !== null) {
                if ("id" in copy[key][0]) {
                    let ids = []
                    copy[key].forEach(element => {
                        ids.push(element.id)
                    })
                    copy[key] = ids
                }
            }
        })
        delete copy.id
        return copy
    }

    static async asyncForEach(array, callback) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array)
        }
    }

    static formatDate(date) {
        let year = date.substring(0, 4)
        let month = parseInt(date.substring(5, 7))
        if (month === 1) month = "Januar"
        else if (month === 2) month = "Februar"
        else if (month === 3) month = "März"
        else if (month === 4) month = "April"
        else if (month === 5) month = "Mai"
        else if (month === 6) month = "Juni"
        else if (month === 7) month = "Juli"
        else if (month === 8) month = "August"
        else if (month === 9) month = "September"
        else if (month === 10) month = "Oktober"
        else if (month === 11) month = "November"
        else if (month === 12) month = "Dezember"
        let day = date.substring(8, 10)
        return day + ". " + month + " " + year
    }

    static formatDateForTable(fullDate) {
        let date = fullDate.split(" ")
        let month = date[1]
        let year = date[2]
        if (month === "Januar") month = 1
        else if (month === "Februar") month = 2
        else if (month === "März") month = 3
        else if (month === "April") month = 4
        else if (month === "Mai") month = 5
        else if (month === "Juni") month = 6
        else if (month === "Juli") month = 7
        else if (month === "August") month = 8
        else if (month === "September") month = 9
        else if (month === "Oktober") month = 10
        else if (month === "November") month = 11
        else if (month === "Dezember") month = 12
        let day = date[0].substring(0, 2)
        return new Date(year + "-" + month + "-" + day)
    }

    static formatDateTimeForTable(fullTime) {
        let date = fullTime.split(" ")
        let time = date[4].split(":")
        let returnDate = this.formatDateForTable(fullTime)
        returnDate.setHours(time[0], time[1])
        return new Date(returnDate)
    }

    static formatDateForAPI(date) {
        date = new Date(date)
        return (date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate())
    }

    static formatHHMM(time) {
        return (new Date(time)).toLocaleTimeString(navigator.language, {
            hour: '2-digit',
            minute: '2-digit'
        })
    }

    static setTimeToZero(date) {
        return new Date(date.setHours(0, 0, 0))
    }

    static formatTime(time) {
        let date = this.formatDate(time)
        let hours = time.substring(11, 13)
        let minutes = time.substring(14, 16)
        return date + " um " + hours + ":" + minutes + " Uhr"
    }

    static isNumeric(str) {
        if (typeof str === "number") return true
        if (typeof str !== "string") return false
        return !isNaN(str) && !isNaN(parseInt(str))
    }

    static isTime(time) {
        return /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(time);
    }

    static isGreaterTime(beginTime, endTime) {
        let begin = beginTime.split(":")
        let end = endTime.split(":")
        if (parseInt(begin[0]) > parseInt(end[0])) return false
        else if (parseInt(begin[0]) === parseInt(end[0])) {
            if (parseInt(begin[1]) >= parseInt(end[1])) return false
        }
        return true
    }

    static decodeJWT(token) {
        let base64Url = token.split('.')[1]
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        }).join(''))
        return JSON.parse(jsonPayload)
    }

    static formatEnum(str) {
        let words = str.toLowerCase().split("_")
        let output = ""
        words.forEach(word => {
            output = output + word.charAt(0).toUpperCase() + word.slice(1) + " "
        })
        return output
    }

    static getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    }

    static getDaysOfMonth(month, year) {
        return new Date(year, month, 0).getDate();
    }

    static getDateFromTableIndex(index) {
        let dayNumber = 0
        let dayError = {59: 3, 123: 4, 185: 5, 278: 6, 340: 7}
        let errorBarriers = Object.keys(dayError)
        if(index > errorBarriers[4]) {
            dayNumber = index + 1 - dayError[errorBarriers[4]]
        } else if(index > errorBarriers[3]) {
            dayNumber = index + 1 - dayError[errorBarriers[3]]
        } else if(index > errorBarriers[2]) {
            dayNumber = index + 1 - dayError[errorBarriers[2]]
        } else if(index > errorBarriers[1]) {
            dayNumber = index + 1 - dayError[errorBarriers[1]]
        } else if(index > errorBarriers[0]) {
            dayNumber = index + 1 - dayError[errorBarriers[0]]
        } else {
            dayNumber = index + 1
        }
        let now = new Date((new Date()).setHours(0, 0, 0, 0))
        let year = now.getFullYear()
        let resultDate = new Date(year, 0, dayNumber)
        if (resultDate.getMonth() < now.getMonth()) resultDate.setYear(year + 1)
        return new Date(resultDate)
    }

    static getDayCountFromDate(date) {
        date = new Date(date)
        let index = 0
        let dayNumber = (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000
        let dayError = {59: 3, 120: 4, 181: 5, 273: 6, 334: 7}
        let errorBarriers = Object.keys(dayError)
        if(dayNumber > errorBarriers[4]) {
            index = dayNumber - 1 + dayError[errorBarriers[4]]
        } else if(dayNumber > errorBarriers[3]) {
            index = dayNumber - 1 + dayError[errorBarriers[3]]
        } else if(dayNumber > errorBarriers[2]) {
            index = dayNumber - 1 + dayError[errorBarriers[2]]
        } else if(dayNumber > errorBarriers[1]) {
            index = dayNumber - 1 + dayError[errorBarriers[1]]
        } else if(dayNumber > errorBarriers[0]) {
            index = dayNumber - 1 + dayError[errorBarriers[0]]
        } else {
            index = dayNumber - 1
        }
        return index
    }

    static getFormattedDateFromTableIndex(index) {
        let date = this.getDateFromTableIndex(index)
        return (date.toLocaleDateString("de-DE", {weekday: 'short'}) + ". " + date.getDate() + "." + (parseInt(date.getMonth()) + 1).toString())
    }

    static getFormattedDateFromDate(pDate) {
        let date = new Date(pDate)
        return (date.toLocaleDateString("de-DE", {weekday: 'short'}) + ". " + date.getDate() + "." + (parseInt(date.getMonth()) + 1).toString())
    }
}