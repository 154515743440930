<template>
  <div class="noGrid">
    <div class="button fRight" @click="generatePDF">PDF erzeugen</div>
    <div>
      <div class="tableHolder printmode" id="table">
        <b>{{ currentMonth }} {{ currentYear }}</b>
        <div class="table">
          <div id="plan"></div>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import {TabulatorFull as Tabulator} from 'tabulator-tables';
import {Praxis} from "@/js/Praxen";
import {Arzt} from "@/js/Arzt";
import {Plan} from "@/js/Plan";
import {Helper} from "@/js/Helper";
import html2pdf from "html2pdf.js/src";


export default {
  name: "Oeffentlich.vue",
  data() {
    return {
      currentMonth: "",
      currentYear: "",
      table: null,
      plan: [],
      tage: {0: "Montag", 1: "Dienstag", 2: "Mittwoch", 3: "Donnerstag", 4: "Freitag", 5: "Samstag", 6: "Sonntag"},
      months: {
        1: "Jan",
        2: "Feb",
        3: "Mär",
        4: "Apr",
        5: "Mai",
        6: "Jun",
        7: "Jul",
        8: "Aug",
        9: "Sep",
        10: "Okt",
        11: "Nov",
        12: "Dez"
      },
      vorlage: null,
    }
  },
  beforeMount() {
    window.topbar.title = "Plan"
    document.title = "Plan"
  },
  async mounted() {
    let _this = this
    let praxen = await Praxis.getPraxen()
    let aerzte = await Arzt.getAerzte()
    let currentDate = new Date()
    let currentYear = currentDate.getFullYear()
    let shift = this.getNumberOfWeekday(new Date(currentYear, 0, 1))
    let row = {tag: ""}
    praxen.forEach(praxis => {
      if (praxis.times !== "GANZER_TAG") {
        row[praxis.name] = {
          name: praxis.name,
          vormittags: null,
          nachmittags: null,
        }
      } else {
        row[praxis.name] = {
          name: praxis.name,
          ganzer_tag: null,
        }
      }
    })
    let columns = [{
      title: "Tag", field: "tag", headerSort: false,
      minWidth: 20,
      formatter: function (cell) {
        let value = cell.getValue()
        if (value !== "") {
          return Helper.getFormattedDateFromTableIndex(_this.plan.indexOf(cell.getRow().getData()))
        }
      }
    }]

    for (let i = 0; i < 12; i++) {
      let daysInMonth = 0
      if (i < currentDate.getMonth()) {
        daysInMonth = Helper.getDaysOfMonth(i + 1, currentYear + 1)
      } else {
        daysInMonth = Helper.getDaysOfMonth(i + 1, currentYear)
      }
      for (let j = 0; j < 31; j++) {
        if (j < daysInMonth) {
          row.tag = this.tage[(j + shift) % 7]
        } else {
          row.tag = ""
        }
        let entry = Helper.clone(row)
        this.plan.push(
            entry
        )
      }
    }
    let sonstiges = null
    Object.keys(row).slice(1).forEach(praxis => {
      if (praxis === "Sonstiges") {
        sonstiges = praxis
        return
      }
      if (typeof row[praxis].ganzer_tag === "object") {
        columns.push({
          title: praxis,
          columns: [
            {
              title: "G. Tag",
              field: praxis + ".ganzer_tag",
              headerSort: false,
              formatter: function (cell) {
                return highlightDoctors(cell)
              }
            },
          ]
        })
      } else {
        columns.push(
            {
              title: praxis,
              columns: [
                {
                  title: "Vorm.",
                  field: praxis + ".vormittags",
                  headerSort: false,
                  formatter: function (cell) {
                    return highlightDoctors(cell)
                  }
                },
                {
                  title: "Nachm.",
                  field: praxis + ".nachmittags",
                  headerSort: false,
                  formatter: function (cell) {
                    return highlightDoctors(cell)
                  }
                }
              ]
            })
      }
    })
    if (sonstiges !== null) {
      if (typeof row[sonstiges].ganzer_tag === "object") {
        columns.push({
          title: sonstiges,
          columns: [
            {
              title: "G. Tag",
              field: sonstiges + ".ganzer_tag",
              headerSort: false,
              formatter: "textarea"
            },
          ]
        })
      } else {
        columns.push(
            {
              title: sonstiges,
              columns: [
                {
                  title: "Vorm.",
                  field: sonstiges + ".vormittags",
                  headerSort: false,
                  width: 200,
                  formatter: "textarea"
                },
                {
                  title: "Nachm.",
                  field: sonstiges + ".nachmittags",
                  headerSort: false,
                  formatter: "textarea"
                }
              ]
            })
      }
    }

    function highlightDoctors(cell) {
      let value = cell.getValue();
      if (aerzte.length === 0) {
        return value
      }
      if (value !== null && value !== undefined) {
        let html = value
        aerzte.forEach(arzt => {
          html = html.replaceAll(arzt.name, "<span style='color:" + arzt.color + "; font-weight:bold;'>" + arzt.name + "</span>")
        })
        return html
      }
    }

    let plan = await Plan.getPlan()
    plan.forEach(entry => {
      this.plan[Helper.getDayCountFromDate(new Date(entry.date))][entry.office.name][entry.time.toLowerCase()] = entry.value
    })

    this.table = new Tabulator(
        "#plan", {
          layout: "fitColumns",
          data: _this.plan,
          index: "date",
          pagination: "local",
          paginationSize: 31,
          columns: columns,
          locale: true,
          langs: {
            "de-de": {
              "columns": {
                "name": "Name", //replace the title of column name with the value "Name"
              },
              "ajax": {
                "loading": "Lädt", //ajax loader text
                "error": "Fehler", //ajax error text
              },
              "pagination": {
                "page_size": "Anzahl", //label for the page size select element
                "page_title": "Zeige Seite",//tooltip text for the numeric page button, appears in front of the page number (eg. "Show Page" will result in a tool tip of "Show Page 1" on the page 1 button)
                "first": "Erste Seite", //text for the first page button
                "first_title": "Erste Seite", //tooltip text for the first page button
                "last": "Letzte Seite",
                "last_title": "Letzte Seite",
                "prev": "Zurück",
                "prev_title": "Vorherige Seite",
                "next": "Vor",
                "next_title": "Nächste Seite",
                "all": "Alle",
              },
            },
          },
        }
    )
    this.table.on("pageLoaded", function (pageno) {
          _this.editPaginationButtons(pageno, currentYear)
        }
    )
    this.table.on("tableBuilt", function () {
      _this.table.setPage(currentDate.getMonth() + 1, currentYear)
    })
  },
  methods: {
    getNumberOfWeekday(date) {
      return parseInt(Helper.getKeyByValue(this.tage, date.toLocaleDateString("de-DE", {weekday: 'long'})));
    },
    editPaginationButtons(pageno, currentYear) {
      let _this = this
      let buttons = Array.from(document.getElementsByClassName("tabulator-pages")[0].children)
      buttons.forEach(button => {
        button.innerHTML = _this.months[button.getAttribute("data-page")]
      })
      let currentDate = new Date()
      _this.currentMonth = new Date(currentYear, pageno - 1).toLocaleString('de-DE', {month: 'long'})
      if (currentDate.getMonth() + 1 > pageno) {
        _this.currentYear = currentYear + 1
      } else {
        _this.currentYear = currentYear
      }
    },
    async savePlan() {
      await Plan.savePlan(this.plan)
    },
    showName(obj) {
      return obj.name
    },
    async generatePDF() {
      let footer = document.querySelector(".tabulator-footer")
      footer.style.display = "none"
      let opt = {
        margin: 0.1,
        filename: 'Plan-' + this.currentMonth + '-' + this.currentYear + '.pdf',
        image: {type: 'jpeg', quality: 0.98},
        html2canvas: {scale: 2},
        jsPDF: {unit: 'in', format: 'letter', orientation: 'landscape'}
      };
      await html2pdf().set(opt).from(document.getElementById("table")).save();
      footer.style.display = "block"
    }
  }
}

</script>

<style scoped>
html, body {
  padding: 0 !important;
}

.button {
  height: 20px !important;
  padding: 3px 20px !important;
}
</style>