<template>
    <div>
        <div class="container">
            <h2>Erfolgreich abgemeldet</h2>
            <div class="textNormal mB">
                Sie haben sich erfolgreich abgemeldet. Ihre Sitzung wurde
                beendet und alle lokalen und temporären Speicher aufgeräumt.
                <br><br>
                <b>Sie können dieses Fenster nun schliessen.</b>
            </div>
            <button class="icon" @click="$router.push('/login')"><vue-feather type="log-in"></vue-feather>zum Login</button>
        </div>
    </div>
</template>

<script>

export default {
    name: "LoggedOut",
  beforeMount() {
    document.title = "Logout"
  }
}
</script>

<style scoped>

</style>
