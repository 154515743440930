import {Api} from "@/js/Api";
import {Alert} from "@/js/Alert";
import router from "@/router";

export class Arzt {
    static async createArzt(arzt) {
        await Api.apiCall({
            query: `
                mutation CreateDoctor($arzt: DoctorInput!){
                        createDoctor(doctor: $arzt){
                            id
                        }
                }`,
            variables: {arzt: arzt},
            success: () => {
                Alert.makeToast('success', "Arzt wurde erstellt.")
                router.replace('/aerzte')
            }
        })
    }

    static async getAerzte() {
        return (await Api.apiCall({
            query: `{
                allDoctors{
                   id
                   name
                   color
                }
            }`,
            needToken: false
        })).data.data.allDoctors
    }

    static async getArzt(id) {
        return (await Api.apiCall({
            query: `{
                doctor(id: ${id}) {
                    id
                    name
                    color
                }
            }`,
            needToken: false,
        })).data.data.doctor
    }

    static async saveArzt(id, arzt) {
        await Api.apiCall({
            query: `
                mutation UpdateDoctor($arzt: DoctorInput!){
                        updateDoctor(id: ${id}, doctor: $arzt)
                }`,
            variables: {arzt: arzt},
            success: () => {
                Alert.makeToast('success', "Arzt wurde bearbeitet.")
            }
        })
    }

    static async deleteArzt(id) {
        return (await Api.apiCall({
            query: `mutation {
                deleteDoctor(id:${id})
            }`,
        })).data.data.deleteDoctor
    }
}