<template>
  <div class="noGrid">
    <br>
    <div>
      <div class="tableHolder" id="table">
        <h2>Plan von {{ arzt.name }}</h2>
        <div class="table">
          <div id="meinPlan"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {TabulatorFull as Tabulator} from 'tabulator-tables';
import {Arzt} from "@/js/Arzt";
import {Plan} from "@/js/Plan";
import {Helper} from "@/js/Helper";

export default {
  name: "MeinPlan.vue",
  data() {
    return {
      myPlan: [],
      table: null,
      arzt: {
        name: "",
      }
    }
  },
  async mounted() {
    let _this = this
    this.arzt = await Arzt.getArzt(this.$route.params.id)
    let plan = await Plan.getPlan()
    plan.sort((a,b) => (new Date(a.date) > new Date(b.date)) ? 1 : ((new Date(b.date) > new Date(a.date)) ? -1 : 0))

    // set document title
    document.title = "Plan von " + this.arzt.name;

    plan.forEach(entry => {
      if (entry.value.includes(this.arzt.name)) {
        let date = new Date(entry.date).setHours(0, 0, 0, 0)
        let today = new Date().setHours(0, 0, 0, 0)
        if (today <= date) {
          if (_this.myPlan.findIndex(x => x.date === entry.date) === - 1) {
            _this.myPlan.push(entry)
          } else {
            entry.date = ""
            _this.myPlan.push(entry)
          }
        }
      }
    })
    _this.table = new Tabulator(
        "#meinPlan", {
          layout: "fitColumns",
          data: _this.myPlan,
          index: "date",
          pagination: "local",
          paginationSize: 20,
          columns: [
            {
              title: "Datum",
              field: "date",
              headerSort: false,
              formatter: function (cell) {
                if (cell.getValue() === "") {
                  return cell.getValue()
                } else {
                  return Helper.getFormattedDateFromDate(cell.getValue())
                }
              }
            },
            {
              title: "Praxis",
              field: "office.name",
              headerSort: false,
            },
            {
              title: "Zeit",
              field: "time",
              headerSort: false,
              formatter: function (cell) {
                return Helper.formatEnum(cell.getValue())
              }
            },
          ],
          locale: true,
          langs: {
            "de-de": {
              "columns": {
                "name": "Name", //replace the title of column name with the value "Name"
              },
              "ajax": {
                "loading": "Lädt", //ajax loader text
                "error": "Fehler", //ajax error text
              },
              "pagination": {
                "page_size": "Anzahl", //label for the page size select element
                "page_title": "Zeige Seite",//tooltip text for the numeric page button, appears in front of the page number (eg. "Show Page" will result in a tool tip of "Show Page 1" on the page 1 button)
                "first": "Erste Seite", //text for the first page button
                "first_title": "Erste Seite", //tooltip text for the first page button
                "last": "Letzte Seite",
                "last_title": "Letzte Seite",
                "prev": "Zurück",
                "prev_title": "Vorherige Seite",
                "next": "Vor",
                "next_title": "Nächste Seite",
                "all": "Alle",
              },
            },
          },
        }
    )
  }
}
</script>

<style scoped>

</style>