import {TabulatorFull as Tabulator} from 'tabulator-tables';

export class Table {

    constructor(selector, data, columns, rowClick) {
        this._columns = columns
        this.searchInput = document.querySelector("#search")
        this.tabulator = new Tabulator(selector, {
            data: data,           //load row data from array
            layout: "fitColumns",      //fit columns to width of table
            responsiveLayout: "hide",  //hide columns that dont fit on the table
            tooltips: true,            //show tool tips on cells
            addRowPos: "top",          //when adding a new row, add it to the top of the table
            history: true,             //allow undo and redo actions on the table
            pagination: "local",       //paginate the data
            paginationSize: 20,         //allow 7 rows per page of data
            movableColumns: false,      //allow column order to be changed
            resizableRows: false,       //allow row order to be changed
            // initialSort: [             //set the initial sort order of the data
            //     { column: "id", dir: "asc"},
            // ],
            columns: columns,
            rowClick: rowClick,
            locale: true,
            langs: {
                "de-de": {
                    "columns": {
                        "name": "Name", //replace the title of column name with the value "Name"
                    },
                    "ajax": {
                        "loading": "Lädt", //ajax loader text
                        "error": "Fehler", //ajax error text
                    },
                    "groups": { //copy for the auto generated item count in group header
                        "item": "Verwaltung", //the singular  for item
                        "items": "Verwaltungen", //the plural for items
                    },
                    "pagination": {
                        "page_size": "Anzahl", //label for the page size select element
                        "page_title": "Show Page",//tooltip text for the numeric page button, appears in front of the page number (eg. "Show Page" will result in a tool tip of "Show Page 1" on the page 1 button)
                        "first": "Erste Seite", //text for the first page button
                        "first_title": "Erste Seite", //tooltip text for the first page button
                        "last": "Letzte Seite",
                        "last_title": "Letzte Seite",
                        "prev": "Zurück",
                        "prev_title": "Vorherige Seite",
                        "next": "Vor",
                        "next_title": "Nächste Seite",
                        "all": "Alle",
                    },
                }
            }
        });
    }

    initFilter(callback) {
        let _this = this
        _this.searchInput.addEventListener("keyup", callback !== undefined ? callback : () => {
            let filterItems = []
            Object.keys(this._columns).forEach((key) => {
                filterItems.push({field: _this._columns[key].field, type: "like", value: _this.searchInput.value})
            })
            _this.tabulator.setFilter([filterItems])
        });
    }

    setFilter(filter) {
        let _this = this
        _this.tabulator.setFilter(filter)
    }
}