<template>
  <div>
    <!-- TopBar -->
    <TopBar/>
    <!-- SideNav -->
    <SideNav/>
    <!-- content -->
    <div class="content">
      <button class="icon mR" @click="$router.back()">
        <vue-feather type="arrow-left"></vue-feather>
        Zurück
      </button>
      <div class="form">
        <h2>Informationen</h2>
        <div>
          <TextInput :title="'Name*'" v-model="name"></TextInput>
        </div>
      </div>
      <div id="vorlage"></div>
      <button class="fLeft" @click="saveVorlage">Speichern</button>
      <button id="delete" class="icon fRight warning" @click="deleteVorlage" v-if="!newVorlage">
        <vue-feather type="trash-2"></vue-feather>
        Löschen
      </button>
    </div>
  </div>
</template>

<script>
import {TabulatorFull as Tabulator} from 'tabulator-tables';
import TopBar from "@/components/TopBar";
import SideNav from "@/components/SideNav";
import TextInput from "@/components/TextInput";
import {Alert} from "@/js/Alert";
import router from "@/router";
import {Vorlage} from "@/js/Vorlage";
import {Praxis} from "@/js/Praxen";
import {Arzt} from "@/js/Arzt";

export default {
  name: "VorlagenDetail",
  components: {TopBar, SideNav, TextInput},
  data() {
    return {
      newVorlage: true,
      table: null,
      name: null,
      vorlage: [
        {tag: "Montag"},
        {tag: "Dienstag"},
        {tag: "Mittwoch"},
        {tag: "Donnerstag"},
        {tag: "Freitag"},
        {tag: "Samstag"},
        {tag: "Sonntag"},
      ]
    }
  },
  beforeMount() {
    if (window.location.pathname === "/vorlagen/neu") {
      this.newVorlage = true
      window.topbar.title = "Vorlage anlegen"
      document.title = "Vorlage anlegen"
    } else {
      this.newVorlage = false
      window.topbar.title = "Vorlage"
      document.title = "Vorlage"
    }
  },
  async mounted() {
    let _this = this
    let praxen = await Praxis.getPraxen()
    let aerzte = await Arzt.getAerzte()
    this.vorlage.forEach(tag => {
      praxen.forEach(praxis => {
        if (praxis.times !== "GANZER_TAG") {
          tag[praxis.name] = {
            name: praxis.name,
            vormittags: null,
            nachmittags: null,
          }
        } else {
          tag[praxis.name] = {
            name: praxis.name,
            ganzer_tag: null,
          }
        }
      })
    })
    let columns = [{title: "Tag", field: "tag", headerSort: false}]
    let sonstiges = null
    Object.keys(this.vorlage[0]).slice(1).forEach(praxis => {
      if(praxis === "Sonstiges") {
        sonstiges = praxis
        return
      }
      if (typeof this.vorlage[0][praxis].ganzer_tag === "object") {
        columns.push({
          title: praxis,
          columns: [
            {
              title: "Ganzer Tag",
              field: praxis + ".ganzer_tag",
              headerSort: false,
              editor: "input",
              formatter: function (cell) {
                return highlightDoctors(cell)
              }
            },
          ]
        })
      } else {
        columns.push(
            {
              title: praxis,
              columns: [
                {
                  title: "Vormittags",
                  field: praxis + ".vormittags",
                  headerSort: false,
                  editor: "input",
                  formatter: function (cell) {
                    return highlightDoctors(cell)
                  }
                },
                {
                  title: "Nachmittags",
                  field: praxis + ".nachmittags",
                  headerSort: false,
                  editor: "input",
                  formatter: function (cell) {
                    return highlightDoctors(cell)
                  }
                }
              ]
            })
      }
    })
    if(sonstiges !== null) {
      if (typeof this.vorlage[0][sonstiges].ganzer_tag === "object") {
        columns.push({
          title: sonstiges,
          columns: [
            {
              title: "Ganzer Tag",
              field: sonstiges + ".ganzer_tag",
              width: 200,
              headerSort: false,
              editor: "textarea",
              formatter: "textarea"
            },
          ]
        })
      } else {
        columns.push(
            {
              title: sonstiges,
              columns: [
                {
                  title: "Vormittags",
                  field: sonstiges + ".vormittags",
                  width: 200,
                  headerSort: false,
                  editor: "textarea",
                  formatter: "textarea"
                },
                {
                  title: "Nachmittags",
                  field: sonstiges + ".nachmittags",
                  width: 200,
                  headerSort: false,
                  editor: "textarea",
                  formatter: "textarea"
                }
              ]
            })
      }
    }
    if (!this.newVorlage) {
      let vorlage = await Vorlage.getVorlage(this.$route.params.id)
      this.name = vorlage.name
      let tage = {montag: 0, dienstag: 1, mittwoch: 2, donnerstag: 3, freitag: 4, samstag: 5, sonntag: 6}
      vorlage.entries.forEach(entry => {
        this.vorlage[tage[entry.date.toLowerCase()]][entry.office.name][entry.time.toLowerCase()] = entry.value
      })
    }

    function highlightDoctors(cell) {
      let value = cell.getValue();
      if(aerzte.length === 0) {
        return value
      }
      if (value !== null && value !== undefined) {
        let html = value
        aerzte.forEach(arzt => {
          html = html.replaceAll(arzt.name, "<span style='color:" + arzt.color + "; font-weight:bold;'>" + arzt.name + "</span>")
        })
        return html
      }
    }

    this.table = new Tabulator("#vorlage", {
      layoutColumnsOnNewData:true,
      layout:"fitData",
      data: _this.vorlage,
      columnVertAlign: "bottom", //align header contents to bottom of cell
      columns: columns,
      locale: true,
      langs: {
        "de-de": {
          "columns": {
            "name": "Name", //replace the title of column name with the value "Name"
          },
          "ajax": {
            "loading": "Lädt", //ajax loader text
            "error": "Fehler", //ajax error text
          },
          "pagination": {
            "page_size": "Anzahl", //label for the page size select element
            "page_title": "Zeige Seite",//tooltip text for the numeric page button, appears in front of the page number (eg. "Show Page" will result in a tool tip of "Show Page 1" on the page 1 button)
            "first": "Erste Seite", //text for the first page button
            "first_title": "Erste Seite", //tooltip text for the first page button
            "last": "Letzte Seite",
            "last_title": "Letzte Seite",
            "prev": "Zurück",
            "prev_title": "Vorherige Seite",
            "next": "Vor",
            "next_title": "Nächste Seite",
            "all": "Alle",
          },
        },
      },
    });
  },
  methods: {
    async saveVorlage() {
      if (this.newVorlage) {
        if (this.name !== null && this.name !== "") {
          await Vorlage.createVorlage(this.name, this.vorlage)
        } else {
          Alert.makeToast('info', "Bitte füllen Sie den Namen aus.")
        }
      } else {
        if (this.name !== null && this.name !== "") {
          await Vorlage.saveVorlage(this.$route.params.id, this.name, this.vorlage)
        } else {
          Alert.makeToast('info', "Bitte füllen Sie den Namen aus.")
        }
      }
    },
    async deleteVorlage() {
      let _this = this
      Alert.makePopUp(
          '"' + _this.name + '"' + " wirklich löschen?",
          async () => {

            if (await Vorlage.deleteVorlage(_this.$route.params.id)) {
              Alert.makeToast('success', _this.name + " wurde gelöscht.")
              router.replace("/vorlagen")
            } else {
              Alert.makeToast('error', "Etwas ist schief gelaufen versuchen Sie es erneut.")
            }
          },
          () => {
          },
          `Ja, löschen`,
          "#d90000"
      )
    },
  }
}
</script>

<style scoped>

</style>