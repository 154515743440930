import axios from "axios";
import {Alert} from "@/js/Alert";
import {User} from "@/js/User";
import {Store} from "@/js/Store";
import router from "@/router";

export class Api {
    static async apiCall({url = window.config.url + window.config.api.endpoint, query, variables = {}, success = () => {}, badUserInputText = "Die Eingaben sind nicht korrekt.", tooManyAttemptsText = "Sie haben zu viele Versuche gebraucht. Warten Sie einen Moment und versuchen Sie es erneut", unauthenticatedIcon = 'info', unauthenticatedText = "Ihre Session ist abgelaufen. Loggen Sie sich bitte erneut ein.", logout = true, needToken = true, token = ""}) {
        if(needToken && token === "") {
            token = await User.getToken()
        }
        let response = await axios({
            method: "POST",
            headers: token !== "" ? {
                Authorization: "Bearer " + token
            } : undefined,
            url: url,
            data: {
                query: query,
            variables: variables
            },
        })
        if (response.status === 200) {
            if ("errors" in response.data) {
                if (response.data.errors[0].extensions.code === "BAD_USER_INPUT") {
                    Alert.makeToast('error', badUserInputText)
                }
                if (response.data.errors[0].extensions.code === "TOO_MANY_ATTEMPTS") {
                    Alert.makeToast('error', tooManyAttemptsText)
                }
                if (response.data.errors[0].extensions.code === "INTERNAL_SERVER_ERROR") {
                    Alert.makeToast('error', "Es ist ein unerwarteter Fehler aufgetreten.")
                }
                if (response.data.errors[0].extensions.code === "UNAUTHENTICATED") {
                    if(logout){
                        Store.destroy({key: "USER_INFORMATION"})
                        Store.destroy({key: "CURRENT_CLIENT"})
                        await router.replace('/login')
                    }
                    Alert.makeToast(unauthenticatedIcon, unauthenticatedText)
                }
                if(response.data.errors[0].extensions.code === "TERMINVEREINBARUNG_STATUS_ALREADY_SET") {
                    if(response.data.errors[0].path[0] === "storniereTermin") {
                        Alert.makeToast('info', "Der Termin wurde bereits storniert.")

                        response.data.data = {storniereTermin: null}
                    } else {
                        Alert.makeToast('info', "Der Termin wurde bereits bestätigt")
                        response.data.data = {bestaetigeTermin: null}
                    }
                    return response
                }
            } else {
                success(response)
                return response
            }
        } else {
            Alert.makeToast('error', "Es ist ein unerwarteter Fehler aufgetreten.")
        }
    }
}