import {Api} from "@/js/Api";
import {Alert} from "@/js/Alert";
import {Praxis} from "@/js/Praxen";
import {Helper} from "@/js/Helper";

export class Plan {

    static async getPlan() {
        return (await Api.apiCall({
            query: `{
                allPlanentries{
                    id
                    date
                    value
                    time
                    office {
                        id
                        name
                        times
                    }
                }
            }`,
            needToken: false
        })).data.data.allPlanentries
    }

    static async savePlan(plan) {
        await this.cleanPlanEintraege()
        await this.createPlanEintraege(plan)
        Alert.makeToast('success', "Plan wurde bearbeitet.")
    }

    static async createPlanEintraege(plan) {
        let query = `mutation CreatePlanentry(`
        let queryMid = ``
        let variables = {}
        let counter = 0
        let praxen = await Praxis.getPraxen()
        for(let i = 0; i < plan.length; i++) {
            Object.keys(plan[i]).splice(1).forEach(praxis => {
                Object.keys(plan[i][praxis]).splice(1).forEach(time => {
                    if (plan[i][praxis][time] !== null) {
                        variables["planentry" + counter] = {
                            date: Helper.formatDateForAPI(Helper.getDateFromTableIndex(i)),
                            value: plan[i][praxis][time],
                            office: parseInt(praxen.find(x => x.name === praxis).id),
                            time: time.toUpperCase()
                        }
                        query += `$planentry` + counter + `: PlanEntryInput!,`
                        queryMid += `m` + counter + `: createPlanentry(planentry: $planentry` + counter + `){
                            id
                        }`
                        counter++
                    }
                })
            })
        }
        if (counter === 0) return []
        query = query.substring(0, query.length - 1)
        query += `){` + queryMid + `}`
        let response = (await Api.apiCall({
            query: query,
            variables: variables,
        })).data.data
        let ids = []
        Object.keys(response).forEach(entry => {
            ids.push(response[entry].id)
        })
        return ids
    }

    static async cleanPlanEintraege() {
        let entries = (await Plan.getPlan())
        let query = `mutation {`
        let counter = 0
        entries.forEach(entry => {
            query += `m` + counter + `:deletePlanentry(id: ${entry.id})`
            counter++
        })
        query += `}`
        if (counter !== 0) {
            return (await Api.apiCall({
                query: query,
            })).data.data.deletePlanentry
        }
    }
}