import {Api} from "@/js/Api";
import {Alert} from "@/js/Alert";
import router from "@/router";

export class Praxis {
    static async createPraxis(praxis) {
        await Api.apiCall({
            query: `
                mutation CreateOffice($praxis: OfficeInput!){
                        createOffice(office: $praxis){
                            id
                        }
                }`,
            variables: {praxis: praxis},
            success: () => {
                Alert.makeToast('success', "Praxis wurde erstellt.")
                router.replace('/praxen')
            }
        })
    }

    static async getPraxen() {
        return (await Api.apiCall({
            query: `{
                allOffices{
                   id
                   name
                   times
                }
            }`,
            needToken: false
        })).data.data.allOffices
    }

    static async getPraxis(id) {
        return (await Api.apiCall({
            query: `{
                office(id: ${id}) {
                    id
                    name
                    times
                }
            }`
        })).data.data.office
    }

    static async savePraxis(id, praxis) {
        await Api.apiCall({
            query: `
                mutation UpdateOffice($praxis: OfficeInput!){
                        updateOffice(id: ${id}, office: $praxis)
                }`,
            variables: {praxis: praxis},
            success: () => {
                Alert.makeToast('success', "Praxis wurde bearbeitet.")
            }
        })
    }

    static async deletePraxis(id) {
        return (await Api.apiCall({
            query: `mutation {
                deleteOffice(id:${id})
            }`,
        })).data.data.deleteOffice
    }
}