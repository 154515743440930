import { createApp } from 'vue'
import App from './App.vue'
import VueAxios from "vue-axios"
import axios from "axios"
import router from "./router"
import VueFeather from 'vue-feather';
import VueSweetalert2 from 'vue-sweetalert2';
import ContextMenu from '@imengyu/vue3-context-menu';
import PopUpInput from "@/components/PopUpInput";

const app = createApp(App)
app.use(VueAxios, axios)
app.use(router)
app.use(VueSweetalert2)
app.use(ContextMenu)
app.component("popupinput", PopUpInput)
app.component(VueFeather.name, VueFeather)
app.mount('#app')
// custom variables
window.topbar = {
    title : ""
}

window.tmpLoginRedirect = "/plan"
// global project config
window.config = {
    url: window.webpackHotUpdate ? "http://localhost:5555" : "",
    api: {
        endpoint: "/api",
        apiVersion: "1.0",
        lang: "de"
    }
}