<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
@import "../node_modules/@suadelabs/vue3-multiselect/dist/vue3-multiselect.css";
@import "./assets/css/select.css";
@import "../node_modules/tabulator-tables/dist/css/tabulator_simple.css";
@import "./assets/css/table.css";
@import "../node_modules/sweetalert2/dist/sweetalert2.min.css";
@import "./assets/css/swal.css";
@import '../node_modules/@imengyu/vue3-context-menu/lib/vue3-context-menu.css';
@import "./assets/css/context-menu.css";
@import "./assets/css/app.css";
@import "./assets/css/topBar.css";
@import "./assets/css/sideNav.css";
@import "./assets/css/content.css";
@import "./assets/css/input.css";
@import "./assets/css/button.css";
@import "./assets/css/container.css";
@import "./assets/css/form.css";
@import "./assets/css/general.css";


:root {
  --accent-color: #506de2;
  --accent-color-semi-transparent: rgba(80, 109, 226, 0.30);
  --navBar-background-color: #506de2;
  --warning-color: #d90000;
}
</style>
