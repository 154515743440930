<template>
  <div class="inputHolder">
    <label :for="id">{{title}}</label>
    <input :id="id" class="input" :type="type == null ? 'text': type" :value="modelValue" @input="$emit('update:modelValue',$event.target.value)" :placeholder="title"/>
  </div>
</template>

<script>
export default {
name: "TextInput.vue",
  props: ["id", "title", "type", "modelValue"],
  data () {
    return {
      content: this.value
    }
  },
}
</script>

<style scoped>

</style>