<template>
  <div class="sideNav">
    <nav class="menu">
      <ul>
        <router-link to="/praxen" custom>
          <li :class="{'router-link-active': subIsActive('/praxen')}"
              @click="navigateTo('/praxen')">
            <vue-feather type="home" stroke-width="3"></vue-feather>
            Praxen
          </li>
        </router-link><router-link to="/aerzte" custom>
        <li :class="{'router-link-active': subIsActive('/aerzte')}"
              @click="navigateTo('/aerzte')">
            <vue-feather type="users" stroke-width="3"></vue-feather>
            Ärzte
          </li>
        </router-link><router-link to="/vorlagen" custom>
        <li :class="{'router-link-active': subIsActive('/vorlagen')}"
              @click="navigateTo('/vorlagen')">
            <vue-feather type="clipboard" stroke-width="3"></vue-feather>
            Vorlagen
          </li>
        </router-link><router-link to="/plan" custom>
        <li :class="{'router-link-active': subIsActive('/plan')}"
              @click="navigateTo('/plan')">
            <vue-feather type="calendar" stroke-width="3"></vue-feather>
            Plan
          </li>
        </router-link>
      </ul>
    </nav>
  </div>
  <!-- END-SIDENAV -->
</template>

<script>
import router from "../router";


export default {
  name: "SideNav",
  methods: {
    navigateTo(routePath, param = "") {
      router.push({path: routePath + param});
    },
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input]
      return paths.some(path => {
        return this.$route.path.indexOf(path) === 0 // current path starts with this path string
      })
    }
  },
}
</script>

<style scoped>

</style>
